import { WidgetProps } from "@rjsf/core";
import { EquipmentBlock } from "components/EquipmentBlock";
import { useEquipmentList } from "components/EquipmentBlock/useEquipmentList";

export const EquipmentBlockWidget = (props: WidgetProps) => {
    const { applicationNumber, appSubmitted } = props.formContext ?? {};
    const [, , , allowEquipmentCopy] = useEquipmentList(applicationNumber);

    if (props.readonly || appSubmitted) {
        return null;
    }

    return <EquipmentBlock allowEquipmentCopy={allowEquipmentCopy} applicationNumber={applicationNumber} />;
};
