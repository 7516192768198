import { Button } from "components/Button";
import { ButtonProps } from "types/ButtonProps";
import { Html } from "./Html";

export function TextWithButton(props: TextWithButtonProps) {
    return (
        <div className="text-with-button">
            <Html {...props} className="d-block" />
            <Button {...props} />
        </div>
    );
}

interface TextWithButtonProps extends ButtonProps {
    text?: string;
    textColor?: string;
    backgroundColor?: string;
}
