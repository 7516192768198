import { isInIframe } from "components/utils/dom";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR, { Cache, mutate } from "swr";
import { EquipmentListItem } from "types/EquipmentListItem";

export const useEquipmentList = (
    applicationNumber?: string
): [
    equipmentList: EquipmentListItem[] | undefined,
    isLoading: boolean,
    requireEquipmentOnPortal: boolean | undefined,
    allowEquipmentCopy: boolean | undefined,
    error: any
] => {
    const url = !isInIframe() && applicationNumber ? getUrl(process.env.REACT_APP_EQUIPMENT_LIST_ENDPOINT, { applicationNumber }) : null;
    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    if (isInIframe()) {
        return [mockedEquipmentList, false, false, false, null];
    }

    return [data?.equipmentWidgetList, isLoading, data?.requireEquipmentOnPortal, data?.allowEquipmentCopy, error];
};

export const refreshEquipmentList = (applicationNumber?: string) => {
    const key = applicationNumber ? getUrl(process.env.REACT_APP_EQUIPMENT_LIST_ENDPOINT, { applicationNumber }) : null;
    mutate(key);
};

export const getEquipmentInfo = (applicationNumber: string | null, cache: Cache) => {
    const key = applicationNumber ? getUrl(process.env.REACT_APP_EQUIPMENT_LIST_ENDPOINT, { applicationNumber }) : null;
    return cache.get(key);
};

const mockedEquipmentList: EquipmentListItem[] = [
    {
        equipid: "043D15F28E2A4D6CB5A2E430E4625839",
        refid: "043D15F28E2A4D6CB5A2E430E4625839",
        name: "Equipment 1",
        category: "Level 2",
        imgFileLocation: "cloud-sun-rain",
        quantity: 13,
        incentive: 600,
    },
    {
        equipid: "0B9EA6706F4411EEA0E7E59731ECB9BE",
        refid: "1DB4CCC2-8D45-4EBE-BDAC-0F4EEC67EAB9",
        name: "Equipment 2",
        category: "Category 1.1",
        imgFileLocation: "lightbulb-cfl",
        quantity: 125,
        incentive: 600,
    },
    {
        equipid: "13A050F0B9CA11EE85DCA57406A67CA9",
        refid: "934A0E5B-A138-4544-B23D-2BE97447F9FF",
        name: "Equipment 3",
        category: "Category 1.1",
        imgFileLocation: "lightbulb-cfl",
        quantity: 1234,
        incentive: 600,
    },
    {
        equipid: "5E1493F0733211EE82B44347E22D5764",
        refid: "A32572AD-DD37-415D-8291-48A6DA68E505",
        name: "Equipment 4",
        category: "Level 2",
        imgFileLocation: "cloud-sun-rain",
        quantity: 333,
        incentive: 600,
    },
];
